<template>
  <div class="fixed inset-0 bg-green-700 bg-opacity-90 flex flex-col items-center justify-center z-50" @click.self="closePopup">
    <div class="absolute top-5 left-1/2 transform -translate-x-1/2">
      <img src="@/assets/knightsgnomeslogo.png" alt="Gnomes vs Knights Logo" class="h-32 mx-auto" />
    </div>
    <div class="relative max-w-4xl w-full bg-transparent p-10 text-white text-center overflow-y-auto h-5/6 scrollbar-thumb-yellow-500 scrollbar-track-transparent scrollbar-thin mt-32">
      <h2 class="text-4xl font-bold mb-6">CHOOSE YOUR SIDE IN THE EPIC BATTLE OF GNOME VS KNIGHTS</h2>
      <p class="mb-4">
        Join the adventure and decide your fate in the clash between the whimsical Gnomes and the noble Knights. Will you fight for gnomekind or uphold the chivalry of the Knights? The choice is yours!
      </p>
      <h3 class="text-2xl font-bold mb-4">The Fight on the Blockchain</h3>
      <p class="mb-4">
        We are waging a war between the $GNOME and $KNIGHT Tokens on the TON network. The two tokens will battle it out with their market caps representing who is winning.
      </p>
      <p class="mb-4">
        On our dapp, you can see the map of the gnome tree city and the knights' castles. Each red tile on the grid represents the knights (<img src="@/assets/knight.svg" alt="Knight" class="inline-block h-6 mx-1" />), each blue tile represents a gnome (<img src="@/assets/gnome.svg" alt="Gnome" class="inline-block h-6 mx-1" />), and the mystery ? tile represents a bullish surprise for the side that reaches it first (<img src="@/assets/prize.svg" alt="Prize" class="inline-block h-6 mx-1" />). It could be a buyback burn or a cash prize for holders.
      </p>
      <p class="mb-4">
        A tile is achieved when you hit a market cap milestone. Each milestone is worked out on a bonding curve and gets exponentially harder to achieve a new tile as the market caps get higher.
      </p>
      <h3 class="text-2xl font-bold mb-4">Bonding Curve Logic</h3>
      <table class="table-auto w-full text-left mb-4">
        <thead>
          <tr>
            <th class="px-4 py-2">Market Cap</th>
            <th class="px-4 py-2">Number of Tiles</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border px-4 py-2">Up to $10,000</td>
            <td class="border px-4 py-2">1 tile for every $2,500</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">$10,001 - $30,000</td>
            <td class="border px-4 py-2">1 tile for every $5,000 + 4 tiles</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">$30,001 - $70,000</td>
            <td class="border px-4 py-2">1 tile for every $10,000 + 8 tiles</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">$70,001 - $150,000</td>
            <td class="border px-4 py-2">1 tile for every $20,000 + 12 tiles</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">$150,001 - $310,000</td>
            <td class="border px-4 py-2">1 tile for every $40,000 + 16 tiles</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">$310,001 - $630,000</td>
            <td class="border px-4 py-2">1 tile for every $80,000 + 20 tiles</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">$630,001 - $1,270,000</td>
            <td class="border px-4 py-2">1 tile for every $160,000 + 24 tiles</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">$1,270,001 - $2,100,000</td>
            <td class="border px-4 py-2">1 tile for every $320,000 + 28 tiles</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">$2,100,001 - $5,000,000</td>
            <td class="border px-4 py-2">1 tile for every $640,000 + 32 tiles</td>
          </tr>
          <tr>
            <td class="border px-4 py-2">More than $5,000,000</td>
            <td class="border px-4 py-2">36 tiles (maximum)</td>
          </tr>
        </tbody>
      </table>
      <p class="mb-4">
        The bonding curve ensures a fair and competitive environment, making each market cap milestone increasingly challenging to achieve, which keeps the game engaging and exciting for all participants.
      </p>
    </div>
    <button @click="closePopup" class="mt-6 px-4 py-2 bg-blue-500 text-white rounded absolute bottom-5">Press ESC to Close</button>
  </div>
</template>

<script>
export default {
  name: 'HowItWorks',
  methods: {
    closePopup() {
      this.$emit('close');
    },
    handleKeyDown(event) {
      if (event.key === 'Escape') {
        this.closePopup();
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
};
</script>

<style scoped>
@import 'tailwindcss/tailwind.css';

.scrollbar-thin::-webkit-scrollbar {
  width: 0.5rem;
}

.scrollbar-thumb-yellow-500::-webkit-scrollbar-thumb {
  background-color: #ecc94b;
}

.scrollbar-track-transparent::-webkit-scrollbar-track {
  background: transparent;
}
</style>
