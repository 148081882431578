<template>
  <div class="flex flex-col sm:flex-row justify-center items-center p-4 bg-transparent custom-font relative">
    <div class="order-2 sm:order-1 sm:mr-4 mb-2 sm:mb-0">
      <a href="https://t.me/joinchat" target="_blank" class="px-4 py-2 bg-opacity-50 bg-gray-700 text-white rounded-full hover:bg-gray-600">TELEGRAM</a>
    </div>
    <div class="order-1 sm:order-2 flex-grow text-center">
      <img src="@/assets/knightsgnomeslogo.png" alt="Gnomes vs Knights Logo" class="max-w-full h-auto mx-auto" style="width: 200px;" />
    </div>
    <div class="order-3 sm:order-3 sm:ml-4 mt-2 sm:mt-0">
      <a href="#" @click.prevent="showHowItWorks" class="px-4 py-2 bg-opacity-50 bg-gray-700 text-white rounded-full hover:bg-gray-600">HOW IT WORKS</a>
    </div>
    <div class="absolute top-4 right-4">
      <button @click="toggleMusic" class="focus:outline-none">
        <svg v-if="isPlaying" class="w-8 h-8 text-white" fill="currentColor" viewBox="0 0 24 24">
          <path d="M3 9v6h4l5 5V4L7 9H3z" />
        </svg>
        <svg v-else class="w-8 h-8 text-white" fill="currentColor" viewBox="0 0 24 24">
          <path d="M3 9v6h4l5 5V4L7 9H3zm13.59-3.41L14 7.17l3.88 3.88-3.88 3.88 1.41 1.41L19.41 12l-3.88-3.88L16.59 5.59z" />
        </svg>
      </button>
    </div>
    <audio ref="audio" :src="require('@/assets/Blod Besvimelse - Misantrop.mp4')" loop></audio>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      isPlaying: false // Start as false to prevent autoplay issues
    };
  },
  methods: {
    showHowItWorks() {
      this.$emit('showHowItWorks');
    },
    toggleMusic() {
      const audio = this.$refs.audio;
      if (this.isPlaying) {
        audio.pause();
      } else {
        audio.play().catch(error => {
          console.log('Play() failed because the user didn\'t interact with the document first:', error);
        });
      }
      this.isPlaying = !this.isPlaying;
    }
  },
  mounted() {
    const audio = this.$refs.audio;
    audio.addEventListener('play', () => {
      this.isPlaying = true;
    });
    audio.addEventListener('pause', () => {
      this.isPlaying = false;
    });
  },
  beforeDestroy() {
    const audio = this.$refs.audio;
    if (audio) {
      audio.pause();
    }
  }
};
</script>

<style scoped>
.custom-font {
  font-family: 'DragonFruitDays', sans-serif;
}
</style>
