<template>
  <div class="flex flex-col items-center w-full h-full">
    <div class="flex justify-center items-center w-full overflow-hidden max-w-4xl mb-5">
      <svg :viewBox="viewBox" class="w-full h-auto">
        <g v-for="hex in hexes" :key="hex.id" :transform="hex.transform" @mouseover="hoverHex(hex.id)" @mouseleave="leaveHex(hex.id)" @click="clickHex(hex.id)">
          <image v-if="hex.image" :href="hex.image" :width="hex.width" :height="hex.height" :x="-hex.width / 2" :y="-hex.height / 2" :class="{'hovered-hex': hex.hovered}" />
        </g>
      </svg>
    </div>
    <div class="flex flex-col items-center w-full mb-5">
      <div class="w-full max-w-4xl mb-5">
        <div class="flex items-center mb-5 relative justify-center">
          <div class="w-4/5 bg-gray-200 rounded-full h-4 relative overflow-hidden slider">
            <div class="slider-bar-left bg-blue-500 h-4 rounded-full absolute left-0 top-0" :style="{ width: knightsPercentage + '%' }"></div>
            <div class="slider-bar-right bg-red-500 h-4 rounded-full absolute right-0 top-0" :style="{ width: gnomesPercentage + '%' }"></div>
            <div class="slider-label-left absolute left-0 top-0 bottom-0 flex items-center justify-center bg-blue-500 text-white rounded-full px-2">
              {{ knightsPercentage }}% ${{ tepeMarketCap }}
            </div>
            <div class="slider-label-right absolute right-0 top-0 bottom-0 flex items-center justify-center bg-red-500 text-white rounded-full px-2">
              {{ gnomesPercentage }}% ${{ apeMarketCap }}
            </div>
          </div>
        </div>
        <div class="flex flex-col sm:flex-row justify-center items-center w-full mt-5 join-buttons space-y-2 sm:space-y-0 sm:space-x-4">
          <img src="@/assets/joinknight.png" alt="Join the Knights" class="join-button cursor-pointer" @click="joinKnights" />
          <img src="@/assets/joingnome.png" alt="Join the Gnomes" class="join-button cursor-pointer" @click="joinGnomes" />
        </div>
      </div>
    </div>
    <HowItWorks v-if="isHowItWorksVisible" @close="isHowItWorksVisible = false" />
  </div>
</template>

<script>
import axios from 'axios';
import HowItWorks from './HowItWorks.vue';

export default {
  components: {
    HowItWorks,
  },
  data() {
    return {
      hexes: [],
      apeMarketCap: 0,
      tepeMarketCap: 0,
      knightCount: 0,
      gnomeCount: 0,
      knightSvg: '/knight.svg',
      gnomeSvg: '/gnome.svg',
      castleSvg: '/castle.svg',
      prizeSvg: '/prize.svg',
      gnomecitySvg: '/gnomecity.svg',
      grassSvg: '/grass.svg',
      hexWidth: 86.6, // Width of each hexagon
      hexHeight: 100, // Height of each hexagon
      rows: 7, // Number of rows
      cols: 14, // Number of columns
      xOffset: 70, // Increased X-offset to add more margin on the left
      yOffset: 100, // Increased Y-offset to add more space for the new row
      totalTiles: 98,
      maxMarketCap: 5000000, // 5 million market cap for full tiles
      padding: 0.5, // Padding between hexagons
      isHowItWorksVisible: false,
    };
  },
  computed: {
    viewBox() {
      return `0 0 ${this.svgWidth} ${this.svgHeight}`;
    },
    svgWidth() {
      return this.hexWidth * (this.cols + 0.5) + this.xOffset + this.padding * this.cols;
    },
    svgHeight() {
      return this.hexHeight * (this.rows * 0.75 + 0.25) + this.yOffset + this.padding * this.rows;
    },
    knightsPercentage() {
      return Math.min((this.tepeMarketCap / this.maxMarketCap) * 100, 100).toFixed(2);
    },
    gnomesPercentage() {
      return Math.min((this.apeMarketCap / this.maxMarketCap) * 100, 100).toFixed(2);
    },
  },
  mounted() {
    this.createHexMap();
    this.fetchMarketCaps();
    this.startMarketCapPolling();
  },
  beforeDestroy() {
    clearInterval(this.marketCapInterval);
  },
  methods: {
    showHowItWorks() {
      this.isHowItWorksVisible = true;
    },
    async fetchMarketCaps() {
      try {
        const apeResponse = await axios.get('https://api.geckoterminal.com/api/v2/networks/ton/pools/EQALgHvyMF3bfjWWJSrD9ByVDNUihxdZCRKzcjFpAqwtnrAk');
        const tepeResponse = await axios.get('https://api.geckoterminal.com/api/v2/networks/ton/pools/EQA0hJzqQ5W8ARqkiHG5W9UcqDwKkZaDrfxabAE3j7SpkxEz');

        const apeFdvUsd = apeResponse.data.data.attributes.fdv_usd;
        const tepeFdvUsd = tepeResponse.data.data.attributes.fdv_usd;

        this.gnomeCount = this.calculateTiles(apeFdvUsd);
        this.knightCount = this.calculateTiles(tepeFdvUsd);

        this.apeMarketCap = apeFdvUsd;
        this.tepeMarketCap = tepeFdvUsd;

        this.updateHexMap();
      } catch (error) {
        console.error('Error fetching market cap data:', error);
      }
    },
    startMarketCapPolling() {
      this.marketCapInterval = setInterval(() => {
        this.fetchMarketCaps();
      }, 20000); // Fetch market caps every 20 seconds
    },
    hexToPixel(q, r) {
      const x = this.hexWidth * (q + 0.5 * (r % 2)) + this.xOffset + this.padding * q;
      const y = this.hexHeight * 0.75 * r + this.yOffset + this.padding * r;
      return { x, y };
    },
    createHexMap() {
      const hexes = [];
      for (let r = 0; r < this.rows; r++) {
        for (let q = 0; q < this.cols; q++) {
          const { x, y } = this.hexToPixel(q, r);
          let image = this.grassSvg;

          // Right wall to gnomecity
          if (q >= this.cols - 1) {
            image = this.gnomecitySvg;
          }

          // Left wall to castle
          if (q < 1) {
            image = this.castleSvg;
          }

          hexes.push({ id: `${r}-${q}`, transform: `translate(${x}, ${y})`, image, hovered: false, width: this.hexWidth, height: this.hexHeight });
        }
      }

      // Add symmetric prize placements
      const prizePositions = [
        { q: 3, r: 1 },
        { q: 3, r: 5 },
        { q: 5, r: 3 },
        { q: 8, r: 3 },
        { q: 10, r: 1 },
        { q: 10, r: 5 },
      ];

      prizePositions.forEach(pos => {
        const { q, r } = pos;
        const index = r * this.cols + q;
        hexes[index].image = this.prizeSvg;
      });

      this.hexes = hexes;
    },
    updateHexMap() {
      this.createHexMap(); // Reset to initial state

      // Place gnomes from the right wall
      let gnomeIndex = 0;
      for (let q = this.cols - 1; q >= 0 && gnomeIndex < this.gnomeCount; q--) {
        for (let r = 0; r < this.rows && gnomeIndex < this.gnomeCount; r++) {
          const hex = this.hexes[r * this.cols + q];
          if (hex.image !== this.castleSvg && hex.image !== this.gnomecitySvg && hex.image !== this.prizeSvg) {
            hex.image = this.gnomeSvg;
            gnomeIndex++;
          }
        }
      }

      // Place knights from the left wall
      let knightIndex = 0;
      for (let q = 0; q < this.cols && knightIndex < this.knightCount; q++) {
        for (let r = 0; r < this.rows && knightIndex < this.knightCount; r++) {
          const hex = this.hexes[r * this.cols + q];
          if (hex.image !== this.castleSvg && hex.image !== this.gnomecitySvg && hex.image !== this.prizeSvg) {
            hex.image = this.knightSvg;
            knightIndex++;
          }
        }
      }
    },
    calculateTiles(marketCap) {
      let tiles = 0;
      if (marketCap <= 10000) {
        tiles = Math.floor(marketCap / 2500);
      } else if (marketCap <= 30000) {
        tiles = 4 + Math.floor((marketCap - 10000) / 5000);
      } else if (marketCap <= 70000) {
        tiles = 8 + Math.floor((marketCap - 30000) / 10000);
      } else if (marketCap <= 150000) {
        tiles = 12 + Math.floor((marketCap - 70000) / 20000);
      } else if (marketCap <= 310000) {
        tiles = 16 + Math.floor((marketCap - 150000) / 40000);
      } else if (marketCap <= 630000) {
        tiles = 20 + Math.floor((marketCap - 310000) / 80000);
      } else if (marketCap <= 1270000) {
        tiles = 24 + Math.floor((marketCap - 630000) / 160000);
      } else if (marketCap <= 2100000) {
        tiles = 28 + Math.floor((marketCap - 1270000) / 320000);
      } else if (marketCap <= 5000000) {
        tiles = 32 + Math.floor((marketCap - 2100000) / 640000);
      } else {
        tiles = 36; // Maximum tiles based on the bonding curve
      }
      return tiles;
    },
    joinKnights() {
      console.log("Join the Knights clicked");
    },
    joinGnomes() {
      console.log("Join the Gnomes clicked");
    },
    hoverHex(id) {
      const hex = this.hexes.find(h => h.id === id);
      hex.hovered = true;
      hex.width = this.hexWidth * 1.1; // Increase size by 10%
      hex.height = this.hexHeight * 1.1; // Increase size by 10%
    },
    leaveHex(id) {
      const hex = this.hexes.find(h => h.id === id);
      hex.hovered = false;
      hex.width = this.hexWidth;
      hex.height = this.hexHeight;
    },
    clickHex(id) {
      const hex = this.hexes.find(h => h.id === id);
      hex.hovered = !hex.hovered;
      hex.width = hex.hovered ? this.hexWidth * 1.1 : this.hexWidth;
      hex.height = hex.hovered ? this.hexHeight * 1.1 : this.hexHeight;
    }
  }
};
</script>

<style scoped>
.app-container {
  background: url('@/assets/foresty.svg') no-repeat center center fixed;
  background-size: cover;
}

.join-buttons {
  justify-content: space-between;
}

.join-button {
  width: 270px; /* Adjust this value to make the images smaller */
  height: auto;
}

.slider {
  height: 30px; /* Adjust the height to match your slider */
}

.slider-bar-left,
.slider-bar-right {
  height: 30px; /* Adjust the height to match your slider */
}

.slider-label-left,
.slider-label-right {
  height: 30px; /* Adjust the height to match your slider */
  line-height: 30px; /* Center the text vertically */
}

.join-telegram {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Translucent background */
  padding: 10px 20px;
}

.join-telegram a {
  color: white;
  font-weight: bold;
  text-decoration: none;
  margin: 0 10px;
}

.hovered-hex {
  transform: scale(1.1);
  transition: transform 0.2s;
}
</style>
