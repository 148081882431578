<template>
  <div class="app-container flex flex-col items-center justify-center w-full h-full">
    <HeaderTop @showHowItWorks="showHowItWorks" />
    <HexMap ref="hexMap" />
  </div>
</template>

<script>
import HeaderTop from './components/HeaderComponent.vue';
import HexMap from './components/HexMap.vue';
import forestyBackground from '@/assets/foresty.svg'; // Correctly import the SVG

export default {
  components: {
    HeaderTop,
    HexMap,
  },
  data() {
    return {
      forestyBackground,
    };
  },
  methods: {
    showHowItWorks() {
      this.$refs.hexMap.showHowItWorks();
    },
  },
};
</script>

<style scoped>
.app-container {
  width: 100%;
  min-height: 100vh; /* Ensures the background covers the full height */
  background-image: url(~@/assets/foresty.svg); /* Correct path for the background image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
